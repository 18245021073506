import styled, { type StyledProps } from "styled-components";
import { COLORS, LAYOUT, SPACING_SCALE } from "@/constants/styles";
import { FILTER_TAG_LOCATION } from "@/constants/tags";
import { overflowShadow } from "@/utils/overflowShadow";

interface StyledFilterTagImageProps {
  active: boolean;
  location?: string;
}

export const StyledFilterTagImage = styled.span<StyledFilterTagImageProps>`
  position: relative;

  &:after {
    background: ${({ active, theme }) =>
      active ? `${theme.buttons.primary_button_background_color}` : "none"};
    bottom: ${({ location }) =>
      location === FILTER_TAG_LOCATION.TAKEOVER
        ? "-15px"
        : `-${LAYOUT.GUTTER / 2}px`};
    content: "";
    display: block;
    height: 2px;
    left: 0px;
    position: absolute;
    width: 100%;

    @media (min-width: 768px) {
      height: 5px;
    }
  }

  img {
    display: block;
    ${({ location }) =>
      location !== FILTER_TAG_LOCATION.TAKEOVER &&
      `
      max-height: 50px;
    `}

    @media (min-width: 768px) {
      ${({ location }: StyledProps<StyledFilterTagImageProps>) =>
        location === FILTER_TAG_LOCATION.TAKEOVER
          ? `width: 100%;`
          : "max-height: 90px;"}
    }
  }
`;

interface StyledFilterTagListProps {
  centeredTags?: boolean;
}

export const StyledFilterTagList = styled.ul<StyledFilterTagListProps>`
  -webkit-overflow-scrolling: touch;
  background: ${({ theme }) => theme.food_hall.filter_nav_background_color};
  border-bottom: solid 1px ${COLORS.GRAY};
  border-top: solid 1px ${COLORS.GRAY};
  display: flex;
  justify-content: space-between;
  margin: 0;
  overflow-x: scroll;
  padding: 0;
  white-space: nowrap;

  ${({ theme }) => overflowShadow(theme.food_hall.filter_nav_background_color)}

  ${({ centeredTags }) =>
    centeredTags &&
    `
    display: block;
    text-align: center;

    @media (min-width: 1025px) {
      overflow: hidden;
    }

    li {
      vertical-align: middle;
      width: auto;
    }

    button {
      padding: 0 ${SPACING_SCALE.XS}px;
      @supports (padding: 0 clamp(${SPACING_SCALE.THREEXS}px, 4vw, ${SPACING_SCALE.LARGE}px)) {
        padding: 0 clamp(${SPACING_SCALE.THREEXS}px, 4vw, ${SPACING_SCALE.LARGE}px);
      }

      @media (min-width: 768px) {
        min-width: 250px;
        padding: 0 ${SPACING_SCALE.LARGE}px;
        @supports (padding: 0 clamp(${SPACING_SCALE.LARGE}px, 6vw, 100px)) {
          padding: 0 clamp(${SPACING_SCALE.LARGE}px, 6vw, 100px);
        }
      }
    }
  `}
`;

export const StyledFilterTagListItem = styled.li`
  display: inline-block;
  flex-grow: 1;
  list-style-type: none;
`;

interface StyledFilterTagProps {
  location?: string;
}

export const StyledFilterTag = styled.button<StyledFilterTagProps>`
  align-items: center;
  background: none;
  border: none;
  display: ${({ location }) =>
    location === FILTER_TAG_LOCATION.TAKEOVER ? "block" : "flex"};
  height: 80px;
  justify-content: center;
  margin: 0 auto;
  width: 100%;

  ${({ location }) =>
    location !== FILTER_TAG_LOCATION.TAKEOVER &&
    `
    padding: 0 ${SPACING_SCALE.XS}px;
  `}

  &:hover {
    opacity: 0.8;
    cursor: pointer;

    ${StyledFilterTagImage}:after {
      background: ${({ theme }) =>
        theme.buttons.primary_button_background_color};
    }
  }

  @media (min-width: 768px) {
    height: 125px;
    padding: ${({ location }: StyledProps<StyledFilterTagProps>) =>
      location === FILTER_TAG_LOCATION.TAKEOVER
        ? `${LAYOUT.GUTTER * 2}px ${LAYOUT.GUTTER}px`
        : `0 ${SPACING_SCALE.MEDIUM}px`};
  }
`;

interface StyledTagLabelProps {
  location?: string;
}

export const StyledTagLabel = styled.div<StyledTagLabelProps>`
  align-items: center;
  color: ${({ theme }) => theme.food_hall.filter_nav_text_color};
  display: flex;
  font-size: 14px;
  height: 50px;
  max-width: 90px;
  padding: ${LAYOUT.GUTTER / 4}px;
  text-align: center;
  white-space: normal;

  ${({ location }) =>
    location === FILTER_TAG_LOCATION.TAKEOVER &&
    `
    min-height: 50px;
    max-height: 90px;
  `}

  @media (min-width: 768px) {
    font-size: 16px;
    height: 90px;
    max-width: 110px;

    ${({ location }: StyledProps<StyledTagLabelProps>) =>
      location === FILTER_TAG_LOCATION.TAKEOVER &&
      `
      max-height: none;
    }
  `}
`;
