import { type Category } from "@koala/sdk";
import styled from "styled-components";
import { PRODUCT_LOCATION_LABELS } from "@/constants/checkout";
import { usePopularItems } from "@/features/popular-items/service";
import { Widget } from "@/features/popular-items/widget";
import { useDispatch, useSelector } from "@/redux";
import { getFontStyles } from "@/redux/cmsConfig/utils";
import commerceActions from "@/redux/commerce/actions";
import { selectConveyance } from "@/redux/conveyanceMode/reducer";
import customizeActions from "@/redux/customize/actions";
import { type ILocationsState } from "@/types/locations";

interface Props {
  location: ILocationsState;
  menuCategories: Category[];
}

export function PopularItems({ location, menuCategories }: Props) {
  const dispatch = useDispatch();
  const { time_wanted } = useSelector(selectConveyance);

  const { title, response, enabled, count, getItem } = usePopularItems({
    location: location.detail?.id ?? 0,
    wantedAt: time_wanted,
  });

  if (!enabled || response.status !== "success") {
    return null;
  }

  return (
    <Widget
      items={response.data?.products.map(getItem) ?? []}
      count={count}
      onClick={function (item, index) {
        dispatch(
          commerceActions.commerceItemClick(item, "popular-item", index)
        );

        dispatch(
          customizeActions.setProduct({
            product: item,
            menuCategories,
            label: PRODUCT_LOCATION_LABELS.MENU,
          })
        );
      }}
    >
      <Title>{title}</Title>
    </Widget>
  );
}

const Title = styled.h2(({ theme }) => ({
  ...getFontStyles(theme.menu_category_header.font),
  margin: 0,
}));
