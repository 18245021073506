import { type Category, type Location } from "@koala/sdk";
import { Desktop } from "./desktop";
import { Mobile } from "./mobile";
import { Render } from "@/components/uielements/render";
import { useConfigOverride } from "@/redux/cmsConfig";
import { filterCategoriesByTagId } from "@/utils/menu";

interface Props {
  categories: Category[];
  activeTagIds: number[] | null;
  locationDetail: Location;
}

export function MenuNavigation({
  categories,
  locationDetail,
  activeTagIds,
}: Props) {
  const { food_hall: foodHallEnabled } = useConfigOverride("admin");

  if (!categories) {
    return null;
  }

  // Filter categories by
  // - Any brand-specific hard-coded exclusions
  // - Tag ID, if one is active
  const filteredCategories = categories
    .filter((category: Category) => category.products.length)
    .filter((category: Category) =>
      filterCategoriesByTagId(category, activeTagIds ?? [])
    );

  return (
    <>
      <Render condition={Boolean(!foodHallEnabled || activeTagIds)}>
        <Desktop
          categories={filteredCategories}
          locationDetail={locationDetail}
          activeTagIds={activeTagIds}
        />
      </Render>

      <Mobile
        categories={filteredCategories}
        locationDetail={locationDetail}
        activeTagIds={activeTagIds}
      />
    </>
  );
}
