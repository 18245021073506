import { withRouter, type NextRouter } from "next/router";
import { compose } from "redux";
import {
  CONVEYANCE_TYPES,
  type Category,
  type Location,
  type MenuProductWithCategory,
} from "@koala/sdk";
import get from "lodash/get";
import Link from "next/link";
import { Component } from "react";
import { type ConnectedProps, connect } from "react-redux";
import { genericEventHandler } from "@/analytics/events";
import { EventNames, GlobalEvents } from "@/analytics/events/constants";
import StringAccessor from "@/components/cmsConfig/stringAccessor";
import GenericModal from "@/components/uielements/genericModal";
import {
  StyledAlertCta,
  StyledChangeLocation,
  StyledGenericModalContent,
  StyledGenericModalFooter,
  StyledSecondaryButton,
} from "@/components/uielements/genericModal/styles";
import { PRODUCT_LOCATION_LABELS } from "@/constants/checkout";
import {
  BASKET_ORDER_KEYS,
  K_ANALYTICS_EVENTS,
  MODAL,
} from "@/constants/events";
import { CART_MIGRATION_TYPES } from "@/constants/locations";
import { ROUTES } from "@/constants/routes";
import basketActions from "@/redux/basket/actions";
import conveyanceModeActions from "@/redux/conveyanceMode/actions";
import customizeActions from "@/redux/customize/actions";
import globalActions from "@/redux/global/actions";
import { type RootState } from "@/types/app";
import { migrateBasketItems } from "@/utils/basket";
import * as ErrorReporter from "@/utils/errorReporter";
import { fireGenericOrderEventsHandler } from "@/utils/events";
import { fireGaEvent, gaActions, gaCats } from "@/utils/googleAnalytics";
import { fireKAnalyticsEvent } from "@/utils/koalaAnalytics";

interface State {
  modalShown: boolean;
  productsNotMigrated: MenuProductWithCategory[];
  errorModalShown: boolean;
}

interface Props extends ReduxProps {
  menuCategories: Category[];
  menuLoading: boolean;
}

interface WithRouterProps extends Props {
  router: NextRouter;
}

class CartReconciliation extends Component<WithRouterProps, State> {
  state = {
    modalShown: false,
    productsNotMigrated: [],
    errorModalShown: false,
  };

  componentDidMount() {
    this.checkCartLocation();
  }

  componentDidUpdate(prevProps: ReduxProps) {
    const currentValidity = this.props.fulfillmentModal.addressValidity;

    if (this.props.cartMigration.type && !prevProps.cartMigration.type) {
      this.setState({ modalShown: true });
      return;
    }

    /* If we change delivery locations, need to revalidate address -
     * If not valid, display error modal, else migrate basket and fire analytics event
     */
    if (
      currentValidity !== prevProps.fulfillmentModal.addressValidity &&
      !this.props.fulfillmentModal.display
    ) {
      this.checkValidity();
    }
    this.checkCartLocation();
  }

  checkValidity = () => {
    const currentValidity = this.props.fulfillmentModal.addressValidity;
    switch (currentValidity) {
      case false:
        this.setState({ errorModalShown: true });
        return;
      case true:
        this.migrateBasket();
        this.setState({ errorModalShown: false });
        // KA Event
        fireKAnalyticsEvent(K_ANALYTICS_EVENTS.LOCATION_CHANGE, {
          name: MODAL.CART_MIGRATION_ACCEPTED,
          // @ts-expect-error ensure that `locationDetail` is defined.
          details: `location_name: ${this.props.locationDetail.label}, location_id: ${this.props.locationDetail.id}, fulfillment_change: ${CONVEYANCE_TYPES.DELIVERY}`,
        });
        return;
      default:
        this.setState({ errorModalShown: false });
        return;
    }
  };

  /*
   * Move the items in the cart from one store to another trying to maintain the same items while
   * switching the product and option id's.
   *
   * @param {newLocation} Location
   * @param {newLocationMenuCategories} Category[]
   */
  migrateBasketToNewLocation = (
    newLocation: Location,
    newLocationMenuCategories: Category[]
  ) => {
    const {
      basketContent,
      basketMenuCategories,
      deliveryAddress,
      basketCreatedAt,
    } = this.props;
    const basketMigration = migrateBasketItems(
      basketContent.basket_items,
      basketMenuCategories,
      newLocationMenuCategories
    );

    this.props.replaceBasket(
      basketMigration.migratedBasketItems,
      newLocation,
      newLocationMenuCategories,
      basketCreatedAt,
      // @ts-expect-error differentiate between `null` and `undefined`.
      get(deliveryAddress, "address")
    );

    if (basketMigration.productsNotMatched.length) {
      this.setState({
        productsNotMigrated: basketMigration.productsNotMatched,
      });

      // KA Event
      fireGenericOrderEventsHandler(
        K_ANALYTICS_EVENTS.ITEMS_NOT_AVAILABLE,
        basketContent,
        null,
        [BASKET_ORDER_KEYS.BASKET_PRODUCTS]
      );
    }

    genericEventHandler(GlobalEvents.STORE__BASKET_RECONCILIATION, {
      name: deliveryAddress.type || CONVEYANCE_TYPES.PICKUP, // 'type' set to either delivery or null,
      details: basketMigration.productsNotMatched.length
        ? "products_removed"
        : "no_removals",
    });
  };

  migrateBasket = () => {
    const {
      basketLocation,
      menuCategories,
      locationDetail,
      setCartMigration,
      setProduct,
    } = this.props;
    const pendingItem = get(this.props, "cartMigration.pendingItem");

    // @ts-expect-error ensure that `locationDetail` is defined.
    this.migrateBasketToNewLocation(locationDetail, menuCategories);
    setCartMigration();

    if (pendingItem) {
      setProduct({
        product: pendingItem,
        menuCategories,
        label: PRODUCT_LOCATION_LABELS.MENU,
      });
    }

    // GA Event
    fireGaEvent(gaCats.findShop, gaActions.changeLocation, {
      // @ts-expect-error ensure that `locationDetail` is defined.
      label: `${basketLocation.label} to ${locationDetail.label}`,
    });
  };

  resetBasket = () => {
    const { setCartMigration, setProduct, menuCategories } = this.props;
    // TODO: add types for cartMigration.pendingItem
    const pendingItem = get(this.props, "cartMigration.pendingItem");
    setCartMigration();
    setProduct({
      product: pendingItem,
      menuCategories,
      label: PRODUCT_LOCATION_LABELS.MENU,
    });
  };

  checkCartLocation = () => {
    const {
      basketLocation,
      cartMigration,
      locationDetail,
      locationsLoading,
      menuLoading,
      setCartMigration,
    } = this.props;
    const { modalShown } = this.state;

    // Pass if we're already displaying the modal or if the modal has already been shown
    if (cartMigration.type || modalShown) {
      return;
    }

    // Test conditions when to present cart to new location migration modal
    if (
      locationDetail?.id &&
      basketLocation.id &&
      !locationsLoading &&
      !menuLoading
    ) {
      if (locationDetail?.id !== basketLocation.id) {
        setCartMigration(CART_MIGRATION_TYPES.MIGRATE);
      }
    }
  };

  render() {
    const {
      router,
      basketContent,
      basketLocation,
      cartMigration,
      locationDetail,
      setCartMigration,
      basketFulfillment,
      clearDeliveryAddress,
      checkDeliveryAddress,
      fulfillmentModal,
      toggleFulfillmentModal,
    } = this.props;
    const { productsNotMigrated, errorModalShown } = this.state;

    const deliveryOnlyLocation = locationDetail?.is_delivery_only;

    return (
      <>
        {/* Cart Migration Modal */}
        <GenericModal
          modalOpen={!!cartMigration.type && !errorModalShown}
          toggleModal={() => setCartMigration()}
          name={MODAL.CART_MIGRATION_MODAL}
          disableModalClose={deliveryOnlyLocation}
          header={`Your current order is set for ${basketFulfillment?.type} from ${basketLocation.label}`}
        >
          <StyledGenericModalContent>
            <p style={{ padding: 20 }}>
              Please confirm you would like to update your order to our{" "}
              {/* @ts-expect-error ensure that `locationDetail` is defined. */}
              {locationDetail.label} store.
            </p>
          </StyledGenericModalContent>

          <StyledGenericModalFooter>
            {/* If a user has previously entered a delivery address and clicks on new location */}
            {basketFulfillment && basketFulfillment.address ? (
              <>
                {!deliveryOnlyLocation && (
                  <StyledAlertCta
                    size="large"
                    // @ts-expect-error ensure that `locationDetail` is defined.
                    aria-label={`Pickup from ${locationDetail.label}`}
                    onClick={() => {
                      this.migrateBasket();
                      // @ts-expect-error ensure that `locationDetail` is defined.
                      clearDeliveryAddress(locationDetail.id);
                      // KA Event
                      fireKAnalyticsEvent(K_ANALYTICS_EVENTS.LOCATION_CHANGE, {
                        name: MODAL.CART_MIGRATION_ACCEPTED,
                        // @ts-expect-error ensure that `locationDetail` is defined.
                        details: `location_name: ${locationDetail.label}, location_id: ${locationDetail.id}, fulfillment_change: ${CONVEYANCE_TYPES.PICKUP}`,
                      });
                    }}
                  >
                    {/* @ts-expect-error ensure that `locationDetail` is defined. */}
                    Pickup from {locationDetail.label}
                  </StyledAlertCta>
                )}

                {/* If new store supports delivery */}
                {/* @ts-expect-error ensure that `locationDetail` is defined. */}
                {locationDetail.supports_delivery && (
                  <StyledAlertCta
                    size="large"
                    // @ts-expect-error ensure that `locationDetail` is defined.
                    aria-label={`Deliver from ${locationDetail.label}`}
                    onClick={() => {
                      /** @TODO ensure that basketFulfillment.address is defined. */
                      checkDeliveryAddress(
                        // @ts-expect-error
                        basketFulfillment.address,
                        locationDetail,
                        true
                      );
                      this.checkValidity();
                    }}
                  >
                    {/* @ts-expect-error ensure that `locationDetail` is defined. */}
                    Deliver from {locationDetail.label}
                  </StyledAlertCta>
                )}
              </>
            ) : (
              <StyledAlertCta
                size="large"
                aria-label={`Update order to ${locationDetail?.label}`}
                onClick={() => {
                  this.migrateBasket();
                  // KA Event
                  fireKAnalyticsEvent(K_ANALYTICS_EVENTS.LOCATION_CHANGE, {
                    name: MODAL.CART_MIGRATION_ACCEPTED,
                    details: `location_name: ${locationDetail?.label}, location_id: ${locationDetail?.id}`,
                  });
                }}
              >
                Update Order
              </StyledAlertCta>
            )}

            {/* Secondary CTA if we've landed on the store menu  */}
            {cartMigration.type === CART_MIGRATION_TYPES.MIGRATE &&
              (deliveryOnlyLocation ? (
                <StyledChangeLocation>
                  <Link href={ROUTES.LOCATIONS} passHref={true}>
                    <a
                      onClick={() => {
                        toggleFulfillmentModal(true);
                        genericEventHandler(GlobalEvents.GENERIC__CTA, {
                          name: EventNames.CHANGE_LOCATION,
                          details: MODAL.CART_MIGRATION_MODAL,
                        });
                      }}
                    >
                      <StringAccessor accessor="cart_checkout.change_location_cta" />
                    </a>
                  </Link>
                </StyledChangeLocation>
              ) : (
                <StyledSecondaryButton
                  // @ts-expect-error ensure that `locationDetail` is defined.
                  aria-label={`Don't update order to ${locationDetail.label}`}
                  onClick={() => {
                    setCartMigration();
                    // KA Event
                    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.LOCATION_CHANGE, {
                      name: MODAL.CART_MIGRATION_DISMISSED,
                      details: `location_name: ${
                        locationDetail?.label ?? ""
                      }, location_id: ${locationDetail?.id ?? ""}`,
                    });
                    void router.push(
                      `${ROUTES.HOMEPAGE}/store/${basketContent.location.id}`
                    );
                  }}
                >
                  Don&apos;t Update Order
                </StyledSecondaryButton>
              ))}

            {/* Secondary CTA if we've clicked on a product  */}
            {cartMigration.type === CART_MIGRATION_TYPES.RESET &&
              (deliveryOnlyLocation ? (
                <StyledChangeLocation>
                  <Link href={ROUTES.LOCATIONS} passHref={true}>
                    <a
                      onClick={() => {
                        toggleFulfillmentModal(false);
                        genericEventHandler(GlobalEvents.GENERIC__CTA, {
                          name: EventNames.CHANGE_LOCATION,
                          details: MODAL.CART_MIGRATION_MODAL,
                        });
                      }}
                    >
                      <StringAccessor accessor="cart_checkout.change_location_cta" />
                    </a>
                  </Link>
                </StyledChangeLocation>
              ) : (
                <>
                  <StyledSecondaryButton
                    onClick={() => {
                      this.resetBasket();
                      // KA Event
                      fireKAnalyticsEvent(K_ANALYTICS_EVENTS.LOCATION_CHANGE, {
                        name: MODAL.CART_MIGRATION_DISMISSED,
                        // @ts-expect-error ensure that `locationDetail` is defined.
                        details: `location_name: ${locationDetail.label}, location_id: ${locationDetail.id}`,
                      });
                    }}
                  >
                    Continue Without Updating
                  </StyledSecondaryButton>
                  <p style={{ fontStyle: "italic" }}>
                    Adding an item without updating the location will clear the
                    cart before adding new items.
                  </p>
                </>
              ))}
          </StyledGenericModalFooter>
        </GenericModal>

        {/* Alert if some products have not been successfully migrated */}
        <GenericModal
          modalOpen={!!productsNotMigrated.length}
          toggleModal={() => this.setState({ productsNotMigrated: [] })}
          name={MODAL.CART_MIGRATION_NOTICES_MODAL}
          header={"Please check your order"}
        >
          <StyledGenericModalContent>
            {productsNotMigrated.length > 0 && (
              <div style={{ padding: 20, textAlign: "left" }}>
                <p>
                  Sorry, but the {basketLocation.label} location does not have
                  the following products and they have been removed from your
                  order:
                </p>
                <ul>
                  {productsNotMigrated.map(
                    (product: MenuProductWithCategory, index) => (
                      <li key={index}>{product.name}</li>
                    )
                  )}
                </ul>
              </div>
            )}
          </StyledGenericModalContent>
          <StyledGenericModalFooter>
            <StyledSecondaryButton
              aria-label="Got it"
              onClick={() => this.setState({ productsNotMigrated: [] })}
            >
              Got it
            </StyledSecondaryButton>
          </StyledGenericModalFooter>
        </GenericModal>

        {/* Alert if new selected location does not delivery to address */}
        <GenericModal
          modalOpen={errorModalShown}
          toggleModal={() => this.setState({ errorModalShown: false })}
          name={MODAL.CANNOT_DELIVER_TO_ADDRESS_MODAL}
          header="Sorry! Issue with Delivery"
          disableModalClose={deliveryOnlyLocation}
        >
          <StyledGenericModalContent>
            <div style={{ padding: 20, textAlign: "left" }}>
              <p>
                {/* Return delivery coverage error from Olo */}
                {fulfillmentModal.errorMessage}
              </p>
            </div>
          </StyledGenericModalContent>
          <StyledGenericModalFooter>
            {!deliveryOnlyLocation && (
              <StyledAlertCta
                size="large"
                // @ts-expect-error ensure that `locationDetail` is defined.
                aria-label={`Pickup from ${locationDetail.label}`}
                onClick={() => {
                  this.migrateBasket();
                  this.setState({ errorModalShown: false });
                  // @ts-expect-error ensure that `locationDetail` is defined.
                  clearDeliveryAddress(locationDetail.id);
                  // KA Event
                  fireKAnalyticsEvent(K_ANALYTICS_EVENTS.LOCATION_CHANGE, {
                    name: MODAL.CART_MIGRATION_ACCEPTED,
                    // @ts-expect-error ensure that `locationDetail` is defined.
                    details: `location_name: ${locationDetail.label}, location_id: ${locationDetail.id}, fulfillment_change: ${CONVEYANCE_TYPES.PICKUP}`,
                  });
                }}
              >
                Switch to Pickup
              </StyledAlertCta>
            )}

            {/* If we could not validate the previous address with the new location - CTA to bring up the fulfillment modal */}
            <StyledAlertCta
              size="large"
              // @ts-expect-error ensure that `locationDetail` is defined.
              aria-label={`Retry Delivery from ${locationDetail.label}`}
              onClick={() => {
                this.setState({ errorModalShown: false });
                setCartMigration();
                toggleFulfillmentModal(
                  true,
                  locationDetail,
                  CONVEYANCE_TYPES.DELIVERY,
                  false,
                  /** @TODO differentiate between `null` and `undefined`. */
                  // @ts-expect-error
                  null,
                  true,
                  deliveryOnlyLocation
                );
                this.checkValidity();

                // Log in error reporter to see if this actually happens
                ErrorReporter.captureMessage(
                  "Delivery address or details not valid at new store location. User to re-enter info",
                  "info",
                  {
                    deliveryAddress: basketFulfillment.address,
                    // @ts-expect-error ensure that `locationDetail` is defined.
                    storeId: locationDetail.id,
                    basketLocationId: basketLocation.id,
                  }
                );
              }}
            >
              Re-Enter Delivery Details
            </StyledAlertCta>

            {deliveryOnlyLocation ? (
              <StyledChangeLocation>
                <Link href={ROUTES.LOCATIONS} passHref={true}>
                  <a
                    onClick={() => {
                      toggleFulfillmentModal(false);
                      genericEventHandler(GlobalEvents.GENERIC__CTA, {
                        name: EventNames.CHANGE_LOCATION,
                        details: MODAL.CART_MIGRATION_NOTICES_MODAL,
                      });
                    }}
                  >
                    <StringAccessor accessor="cart_checkout.change_location_cta" />
                  </a>
                </Link>
              </StyledChangeLocation>
            ) : (
              <StyledSecondaryButton
                // @ts-expect-error ensure that `locationDetail` is defined.
                aria-label={`Don't update order to ${locationDetail.label}`}
                onClick={() => {
                  this.setState({ errorModalShown: false });
                  setCartMigration();
                  // KA Event
                  fireKAnalyticsEvent(K_ANALYTICS_EVENTS.LOCATION_CHANGE, {
                    name: MODAL.CART_MIGRATION_DISMISSED,
                    // @ts-expect-error ensure that `locationDetail` is defined.
                    details: `location_name: ${locationDetail.label}, location_id: ${locationDetail.id}`,
                  });
                }}
              >
                Don&apos;t Update Order
              </StyledSecondaryButton>
            )}
          </StyledGenericModalFooter>
        </GenericModal>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  basketContent: state.app.basket.content,
  basketLocation: state.app.basket.location,
  basketCreatedAt: state.app.basket.createdAt,
  basketFulfillment: state.app.basket.fulfillment,
  cartMigration: state.app.global.cartMigration,
  deliveryAddress: state.app.conveyanceMode,
  basketMenuCategories: state.app.menu.basketMenu,
  locationDetail: state.app.locations.detail,
  locationsLoading: state.app.locations.loading,
  fulfillmentModal: state.app.global.fulfillmentModal,
});

const mapDispatchToProps = {
  setCartMigration: globalActions.setCartMigration,
  setProduct: customizeActions.setProduct,
  replaceBasket: basketActions.replaceBasket,
  checkDeliveryAddress: conveyanceModeActions.checkDeliveryAddress,
  clearDeliveryAddress: conveyanceModeActions.clearDeliveryAddress,
  toggleFulfillmentModal: globalActions.toggleFulfillmentModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export default compose(
  connector,
  withRouter
)(CartReconciliation) as React.ComponentType<{
  menuCategories: Category[];
  menuLoading: boolean;
}>;
