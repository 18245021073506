import { sdkUtils, type Category, type Location } from "@koala/sdk";
import Link from "next/link";
import styled from "styled-components";
import { useDispatch, useSelector } from "@/redux";
import { getFontStyles } from "@/redux/cmsConfig/utils";
import menuActions from "@/redux/menu/actions";
import { supportsBrandId } from "@/utils/config";
import { fireGaEvent, gaActions, gaCats } from "@/utils/googleAnalytics";
import { deriveCategoryNavId, updateStoreMenuUrl } from "@/utils/menu";

interface Props {
  categories: Category[];
  locationDetail: Location;
  activeTagIds: number[] | null;
  context: string;
}

export function List({
  categories,
  locationDetail,
  activeTagIds,
  context,
}: Props) {
  const dispatch = useDispatch();
  const { navigationStatus } = useSelector((state) => state.app.menu);
  const { webConfig } = useSelector((state) => state.app.cmsConfig);
  const useBrandId = supportsBrandId(webConfig);

  return (
    <>
      {categories.map((category, index) => {
        const url = updateStoreMenuUrl(
          {
            // @ts-expect-error id can't be undefined, which is a valid value for `locationDetail.brand_id`
            id: useBrandId ? locationDetail.brand_id : locationDetail.id,
            name: locationDetail.label,
            catId: String(category.id),
            catName: category.name,
          },
          activeTagIds
        );

        return (
          <Link
            href={url}
            replace
            shallow
            passHref
            key={`${sdkUtils.slugName(category)}-${index}`}
          >
            <StyledMenuNavLink
              data-testid="menu-navigation-category"
              id={deriveCategoryNavId(String(category.global_id), context)}
              active={
                navigationStatus
                  ? String(category.id) === navigationStatus
                  : index === 0
              }
              onClick={() => {
                fireGaEvent(gaCats.browse, gaActions.clickCategory, {
                  label: category.name,
                });
                dispatch(
                  menuActions.requestNavigationUpdate(String(category.id))
                );
              }}
            >
              <span>{category.name}</span>
            </StyledMenuNavLink>
          </Link>
        );
      })}
    </>
  );
}
export const StyledMenuNavLink = styled.a<{ active?: boolean }>(
  ({ theme, active = false }) => ({
    borderBottom: "3px solid transparent",
    borderTop: "3px solid transparent",
    cursor: "pointer",
    opacity: active ? 1 : 0.6,
    padding: "5px 10px 5px 0",
    textAlign: ["left", "column"].includes(
      theme.menu_categories.category_nav_display
    )
      ? "left"
      : "center",
    transition: "all 0.2s linear",
    ...getFontStyles(theme.menu_categories.category_nav_font, {
      color: (value) => {
        if (active) {
          return theme.menu_categories.category_nav_text_hover_color;
        }

        return value;
      },
    }),
    borderBottomColor: active
      ? theme.menu_categories.category_nav_text_hover_color
      : "transparent",

    "&:hover": {
      borderBottomColor: theme.menu_categories.category_nav_text_hover_color,
      color: theme.menu_categories.category_nav_text_hover_color,
      opacity: 1,
      textDecoration: "none",
    },
  })
);
