export const CATEGORY_NAV_DISPLAY = {
  STICKY: "sticky",
  INLINE: "inline",
  COLUMN: "column",
  LEFT: "left",
};

export const CATEGORY_MENU_HEADER_DISPLAY = {
  COLUMN: "column",
  STACKED: "stacked",
};

export const MOBILE_CATEGORY_NAV_DISPLAY = {
  SCROLL: "scroll",
  DROPDOWN: "dropdown",
};

export enum STORE_BANNER_DISPLAY {
  NEVER = "never",
  ALWAYS = "always",
  STORE_CLOSED = "only_if_store_is_closed",
}
