import { throttle } from "lodash";
import { useRef, useCallback } from "react";

interface ThrottledListener {
  ref: React.RefObject<HTMLElement | null>;
  listener: () => void;
}
/**
  * Provides an abstraction to create throttled events in a react style way
  *
  * The following will create a throttled event that fires every 1000ms a performs
  * some action that should happen. An optional "refreshToken" boolean can sent
  * to restart the memoized throttle.
  *
  * ```
  * const { ref, listener } = useThrottledListener(
    () => {
      // do something
    },
    1000,
    refreshToken,
   );
  * ```
  * @param shouldRefresh
  * @param duration
  * @returns ThrottledListener
  */
export const useThrottledListener = (
  callback: () => void,
  duration: number,
  shouldRefresh = false
): ThrottledListener => {
  const ref: React.RefObject<HTMLElement | null> = useRef(null);

  const listener = useCallback(throttle(callback, duration), [shouldRefresh]);

  return {
    ref,
    listener,
  };
};
