import styled from "styled-components";

interface Props {
  isVisible: boolean;
}

export const StyledChildren = styled.div`
  position: relative;
  transition: all 0.05s ease-in-out;
  z-index: 1;

  ${(props: Props) => `
    opacity: ${(props.isVisible && 1) || 0}; 
    visibility: ${(props.isVisible && "visible") || "hidden"};
    pointer-events: ${(props.isVisible && "all") || "none"};
    transition: opacity .05s ease-in-out,
                visibility .0s .05s;
  `}
`;
