import { readableColor } from "polished";
import styled from "styled-components";
import { getFontStyles } from "@/redux/cmsConfig/utils";
import { safePolished } from "@/utils/safePolished";

export const StyledStoreBanner = styled.div(({ theme }) => ({
  backgroundColor: theme.store_banner.background_color ?? "transparent",
  borderBottom: `1px solid ${theme.store_banner.border_color ?? "transparent"}`,
  padding: "20px",
  textAlign: "center",
}));

export const StyledStoreBannerContent = styled.div(({ theme }) => ({
  margin: "0 auto",
  maxWidth: "900px",

  p: {
    margin: 0,
    ...getFontStyles(theme.store_banner.font, {
      color: (value) =>
        value ??
        safePolished(readableColor)(theme.store_banner.background_color),
    }),
  },
}));
