import { type Location } from "@koala/sdk";
import {
  getPopularProducts,
  getWebConfig,
  getTags,
  getMenu,
} from "@koala/sdk/v4";
import { Hydrate, QueryClient } from "@tanstack/react-query";
import Router from "next/router";
import { useEffect } from "react";
import { END } from "redux-saga";
import Layout from "@/components/app/layout/index";
import { StoreHead } from "@/components/store/pageTitle";
import { DELIVERY_TIME_WANTED_MODES } from "@/constants/global";
import { LOCATION_STATUSES } from "@/constants/locations";
import { ROUTES } from "@/constants/routes";
import { StoreDetails } from "@/features/store/details";
import { useDispatch, useSelector } from "@/redux";
import allergenActions from "@/redux/allergens/actions";
import { locationsActions } from "@/redux/locations/actions";
import { wrapper, type SagaStore } from "@/redux/store";
import { createHttpClient } from "@/services/client";
import { getFooterTemplate } from "@/services/footer.service";
import {
  fetchLocation,
  fetchLocationByBrandId,
} from "@/services/locations.service";
import { queryOnServer } from "@/services/ssr.service";
import { getOrigin } from "@/utils";
import { supportsBrandId } from "@/utils/config";
import { fireKAnalyticsEvent } from "@/utils/koalaAnalytics";
import { K_ANALYTICS_EVENTS, LOG_EVENTS } from "@/constants/events";
import {
  parseLocationRouteParams,
  type StoreRouteParams,
} from "@/utils/locations";

interface Props {
  routeParams: StoreRouteParams;
  serverState: unknown;
}

interface ServerStateQuery {
  queryKey: string[];
  queryHash: string;
  state: {
    data: any[];
  };
}

const Store = ({ serverState, routeParams }: Props) => {
  const { locations } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allergenActions.fetchAllergens());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const serverStateQueries = serverState as { queries?: any[] };
    let menuQueryState: { key: string; hasProducts: boolean } | null = null;
    serverStateQueries?.queries?.forEach((query: ServerStateQuery) => {
      if (query?.queryKey[0] === "menu") {
        menuQueryState = {
          key: query.queryHash,
          hasProducts: query.state?.data[0]?.some(
            (category: { products: any[] }) => category.products.length > 0
          ),
        };
      }
    });
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.LOG, {
      name: LOG_EVENTS.MENU_SSR_FETCHED,
      details: JSON.stringify(menuQueryState),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Hydrate state={serverState}>
      <StoreHead routeParams={routeParams} />

      <Layout
        // Don't render page if location is inactive
        disabled={locations.detail?.status_id === LOCATION_STATUSES.INACTIVE}
      >
        <StoreDetails routeParams={routeParams} />
      </Layout>
    </Hydrate>
  );
};

Store.getInitialProps = wrapper.getInitialPageProps(
  (store) =>
    async ({ req, query, res }) => {
      /**
       * 🚨 REDUX SAGAS TRIGGERED SERVER-SIDE THAT CALL THE SDK WILL NOT WORK!
       * All SDK-dependant Sagas rely on `typeof window !== "undefined"` in
       * order to determine the request origin. This condition won't be met
       * server-side and the sagas will fail silently.
       *
       * If you need to make SDK requests server-side, move the fetch logic
       * directly into this function for now!
       *
       * @see https://github.com/Chowly/koala-ordering-webapp/pull/1786
       */

      let origin: string;
      if (typeof window !== "undefined") {
        origin = getOrigin(window.location.host);
      } else if (req?.headers.host) {
        origin = getOrigin(req.headers.host);
      } else {
        throw new Error("Missing request origin");
      }
      const sdkClient = createHttpClient({ origin });
      const reactQueryClient = new QueryClient();
      const webConfig = await getWebConfig({ client: sdkClient });
      const routeParams = parseLocationRouteParams(query);

      let locationDetails: { data: Location };

      try {
        if (supportsBrandId(webConfig)) {
          locationDetails = await fetchLocationByBrandId(routeParams.id, {
            client: sdkClient,
          });

          // locationDetails = await fetchLocationByBrandId(routeParams.id, meta);
        } else {
          /** @TODO remove the parseInt once all locationIDs are strings. */
          locationDetails = await fetchLocation(
            { id: parseInt(routeParams.id, 10) },
            { client: sdkClient }
          );
        }
      } catch (e) {
        // No location was found. Redirect to the homepage.
        /**
         * Handle both server-side and client-side redirects.
         * @see https://maxschmitt.me/posts/next-js-redirects
         */
        if (res) {
          res.writeHead(302, {
            Location: ROUTES.HOMEPAGE,
            "Cache-Control": "no-cache",
          });
          res.end();
        } else {
          await Router.replace("/");
        }

        return {};
      }

      // Hydrate location details and fetch the location's menu
      store.dispatch(locationsActions.hydrateLocation(locationDetails.data));

      if (req) {
        store.dispatch(END);
        await (store as SagaStore).sagaTask?.toPromise();
      }

      let serverRequests = [
        {
          queryKey: ["site-footer"],
          queryFn: () => getFooterTemplate(origin),
        },
        {
          queryKey: [
            "popular-items",
            { id: locationDetails.data.id, wantedAt: "asap" },
          ],
          queryFn: () =>
            getPopularProducts(
              { locationId: locationDetails.data.id, maxItems: 15 },
              { client: sdkClient }
            ),
        },
        {
          queryKey: [
            "menu",
            {
              id: locationDetails.data.id,
              ifModifiedSince: undefined,
              wantedAt: DELIVERY_TIME_WANTED_MODES.ASAP,
            },
          ],
          queryFn: () =>
            getMenu({ id: locationDetails.data.id }, { client: sdkClient }),
        },
      ];

      // Only try to fetch tags for food hall brands.
      const state = store.getState();
      if (state.app.cmsConfig.webConfig.admin.food_hall) {
        serverRequests = serverRequests.concat([
          {
            queryKey: ["food-halls"],
            queryFn: () => getTags({ type: "filter" }, { client: sdkClient }),
          },
        ]);
      }

      const serverState = await queryOnServer(serverRequests, reactQueryClient);

      return { serverState, routeParams };
    }
);

export default Store;
