import { sdkUtils, type Category } from "@koala/sdk";
import { useDispatch, useSelector } from "@/redux";
import menuActions from "@/redux/menu/actions";
import { fireGaEvent, gaActions, gaCats } from "@/utils/googleAnalytics";

interface Props {
  categories: Category[];
}

export function DropDown({ categories }: Props) {
  const dispatch = useDispatch();

  const navigationStatus = useSelector(
    (state) => state.app.menu.navigationStatus
  );

  /*
    Dropdown List
    - Accounting for category exclusions
    - Accounting for categories filtered by active tag
  */
  return (
    <select
      title="Choose a category"
      value={navigationStatus}
      onChange={(e) => {
        const selectedCategory = categories.find(
          (category: Category) => String(category.id) === e.target.value
        );

        if (!selectedCategory) {
          return;
        }

        dispatch(
          menuActions.requestNavigationUpdate(String(selectedCategory.id))
        );
        fireGaEvent(gaCats.browse, gaActions.clickCategory, {
          label: `Mobile: ${selectedCategory?.name}`,
        });
      }}
    >
      {categories.map((category: Category, index: number) => (
        <option
          value={String(category.id)}
          key={`${sdkUtils.slugName(category)}-${index}`}
        >
          {category.name}
        </option>
      ))}
    </select>
  );
}
